import React, { useState, useRef } from "react";
import classes from './CustomSelect.module.css';

const CustomSelect = ({ options, value, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef();

    const selectedOption = options.find((option) => option.value === value);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        onChange(option.value); // Pass only the selected value
        setIsOpen(false);
    };


    // Close dropdown when clicking outside
    React.useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    return (
        <div className={classes['custom-select']} ref={dropdownRef}>
            <div className={classes["select-box"]} onClick={toggleDropdown}>
                {selectedOption ? selectedOption.label : placeholder}
                <span className={`${classes.arrow} ${isOpen ? classes.open : ""}`}>&#9660;</span>
            </div>
            {isOpen && (
                <div className={classes["options"]}>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={classes["option"]}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
