import classes from './ExpoPopup.module.css';
import { motion } from 'framer-motion';
import closeIcon from '../../images/close.png';
import { useQuery } from '@tanstack/react-query';
import { fetchData } from '../../util/http.js';
import Block from '../Block/Block.jsx';
import { useTranslation } from "react-i18next";
import iconCurrentExpo from '../../images/icon-current-expo.png';
import bgExpo from '../../images/dashed_border.png';
import bgExpoPopup from '../../images/background-expos.png';
import React from 'react';

export default function ExpoPopup({ closePopup }) {
    const { t, i18n } = useTranslation(["General"]);
    const currentLanguage = i18n.language;
    const currentLanguageURLString = i18n.language === 'en' ? '' : `${i18n.language}/`;

    const fetchUrl = 'https://xn--wsten-kvaa.script.lu/admin/' + currentLanguageURLString + 'jsonapi/node/expo';
    const { data, isPending, isError, error } = useQuery({
        queryFn: () => fetchData(fetchUrl),
        queryKey: ['expos']
    });

    // Helper function to format date as DD/MM/YYYY
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // en-GB formats as DD/MM/YYYY
    };

    // Helper function to check if date_to is in the past
    const isInactive = (dateTo) => {
        const today = new Date();
        const dateToCheck = new Date(dateTo);
        return dateToCheck < today; // Check if date_to is in the past
    };
    const isCurrent = (dateFrom, dateTo) => {
        const today = new Date();
        const dateFromCheck = new Date(dateFrom);
        const dateToCheck = new Date(dateTo);
        return dateToCheck > today && dateFromCheck < today; // Check if date_to is in the past
    };

    let content;

    if (isPending) {
        content = <div>Loading...</div>;
    }

    if (isError) {
        content = <div>Error: {error.message}</div>;
    }

    if (data && Array.isArray(data.data)) {
        console.log(data);
        // Sort data by field_date (oldest to newest)
        const sortedData = data.data.sort((a, b) => {
            const dateA = new Date(a.attributes.field_date);
            const dateB = new Date(b.attributes.field_date);
            return dateA - dateB; // Ascending order
        });

        content = (
            <ul className={classes.dates} style={{ backgroundImage: `url(${bgExpo})` }}>
                {sortedData.map((expo) => {
                    const { title, field_date, field_date_to } = expo.attributes;
                    const inactive = isInactive(field_date_to); // Determine if expo is inactive
                    const iscurrent = isCurrent(field_date, field_date_to); // Determine if expo is inactive

                    return (
                        <li key={expo.id}
                            className={inactive ? classes.inactive : iscurrent ? classes.current : ''}
                            style={iscurrent ? { backgroundImage: `url(${iconCurrentExpo})` } : {}}
                        >
                            <p>
                                {title}<br />
                                {formatDate(field_date)} - {formatDate(field_date_to)}
                            </p>
                        </li>
                    );
                })
                }
            </ul >
        );
    }

    function closeDialog() {
        closePopup();
    }

    return (
        <motion.div className={classes.dialog}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
            transition={{ type: 'spring', duration: 1 }}
            style={{ backgroundImage: `url(${bgExpoPopup})` }}>
            <button onClick={closeDialog}><img src={closeIcon} alt='close' /></button>
            <Block title={t('Where is the exhibition located at the moment')} scheme="dark">
                {content}
            </Block>
        </motion.div>

    );
}
