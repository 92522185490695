import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./LanguageSwitcher.module.css";
import iconContact from "../../images/icon_contact.png";
import React, { useEffect } from 'react';
import CustomSelect from "../Form/CustomSelect";

export default function LanguageSwitcher({ className }) {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // Extract the current language from the URL
    const currentLanguage = location.pathname.split('/')[1] || 'de';



    // Function to change the language and update the URL
    const handleLanguageChange = (newLanguage) => {
        i18n.changeLanguage(newLanguage);
        localStorage.setItem('user-lang', newLanguage);

        // Construct the new path by replacing the old language in the URL
        let newPath = location.pathname;

        // If the URL starts with the current language, replace it with the new language
        if (newPath.startsWith(`/${currentLanguage}`)) {
            newPath = newPath.replace(`/${currentLanguage}`, `/${newLanguage}`);
        } else {
            // If no language is in the URL, just prepend the new language
            newPath = `/${newLanguage}${newPath}`;
        }

        // Navigate to the new language path
        navigate(newPath);
    };
    const options = [
        { value: "en", label: "EN" },
        { value: "fr", label: "FR" },
        { value: "de", label: "DE" },
    ];

    return (
        <div className={`${classes.selectWrapper} ${className}`}>
            <a href="mailto:Isabelle.Schmartz@men.lu"><img className={classes.icon} src={iconContact} alt="Contact Icon" /></a>

            <CustomSelect options={options} value={i18n.language} onChange={handleLanguageChange} placeholder={i18n.language.toUpperCase()} />
        </div>
    );
}
